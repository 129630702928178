
.btn-group-vertical>.btn-group:after, .btn-group-vertical>.btn-group:before, .btn-toolbar:after, .btn-toolbar:before, .clearfix:after, .clearfix:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, .dl-horizontal dd:after, .dl-horizontal dd:before, .form-horizontal .form-group:after, .form-horizontal .form-group:before, .modal-footer:after, .modal-footer:before, .modal-header:after, .modal-header:before, .nav:after, .nav:before, .navbar-collapse:after, .navbar-collapse:before, .navbar-header:after, .navbar-header:before, .navbar:after, .navbar:before, .pager:after, .pager:before, .panel-body:after, .panel-body:before, .row:after, .row:before {
    display: table;
    content: " ";
}
.event-section .section-title h2 {
    position: relative;
    font-size: 36px;
    color: #FFFFFF;
    display: inline-block;
    margin-bottom: 50px;
    padding-right: 40px;
}
.event-section .section-title h2::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 50px;
    top: 0;
    right: 0;
    background-color: #FFFFFF;
}
.event-section .event-wrapper {
    background: #FFFFFF;
    padding: 30px 30px 40px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.event-section .event-wrapper .date {
    font-family: DINPro;
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
    color: #0a68ca;
    border: 2px solid #0a68ca;
    padding: 0px 20px;
}
.event-section .event-wrapper .date .month {
    font-size: 18px;
    line-height: 32px;
    color: #9298a0;
    display: block;
}
.event-section .event-wrapper .wrapper-content {
    margin-left: 118px;
}
.event-section .event-wrapper .wrapper-content h4 {
    font-size: 24px !important;
    line-height: 30px;
    margin-top: -7px;
    margin-bottom: 4px;
}
.event-section .event-wrapper .wrapper-content h4 a {
    color: #1e2e42;
}
.event-section .event-wrapper .wrapper-content .small-text.time {
    margin-right: 15px;
}
.event-section .event-wrapper .wrapper-content .small-text {
    font-size: 14px;
    color: #9298a0;
}
.event-section .event-wrapper .wrapper-content .small-text i {
    color: #0a68ca;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.fa-clock-o:before {
    content: "\f017";
}
:after, :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.event-section .event-wrapper .wrapper-content .small-text {
    font-size: 14px;
    color: #9298a0;
}
.event-section .event-wrapper .wrapper-content .small-text i {
    color: #0a68ca;
}
.fa-map-marker:before {
    content: "\f041";
}
.event-section .event-wrapper .wrapper-content h4 a:hover{
    color: #0a68ca;
}

.counter {
  padding: 20px 0;
  border-radius: 5px;
}

.count-text{
  font-size: 13px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  color: #900404 !important;
}

.fa-2x {
  margin: 0 auto;
  float: none;
  display: table;
  color: #FF5E00;
}

/* @keyframes count-up {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
} */

.timer {
  /* animation: count-up 1s ease-out; */
  font-size: 40px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  color: #900404;
}

@media (max-width: 768px) {
  .row.text-center {
    display: grid;
  }

  .col {
    margin-bottom: 20px;
  }
}
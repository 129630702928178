#priestnew {
  background-color: transparent;
  width: 280px;
  height: 450px;
  perspective: 1000px;

}

#priestnew-inner {
  position: relative;
  width: 100%;
  height: 92%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

#priestnew:hover #priestnew-inner {
  transform: rotateY(180deg);
}

#priestnew-front,
#priestnew-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

#priestnew-front {
  color: black;
}

#priestnew-back {
  transform: rotateY(180deg);
  margin-top: 200px;
}

.social-icons {
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}

.social-icons a {
  margin-right: 10px;
  color: #333;
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #900404 !important;
}

.facebook:hover {
  color: #3b5998 !important;
}

.twitter:hover {
  color: #1da1f2 !important;
}

.instagram:hover {
  color: #c32aa3 !important;
}
.slick-arrow {
  background-color: #900404 !important;
  border-radius: 50% !important;
}
#sliderpriest{
  padding: 10px 10px 10px 10px ;
}
.priestallimg{
  display: inline !important;
}

#slideryoutube {
  padding: 10px 10px 10px 10px;
}
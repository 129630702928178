
  .card {
    padding: 12px 12px 12px 12px;
    color: #1f003b;
    cursor: pointer;
    transition: 0.3s;
    background-color: #ffffff;
  }
  .card .img-container {
    /* width: 8em;
    height: 8em;
    background-color: #a993ff;
    padding: 0.5em;
    border-radius: 50%; */
    margin: 0 auto 2em auto;
  }
  .card img {
    width: 100%;
    /* border-radius: 50%; */
  }
  .card h3 {
    font-weight: 500;
    font-size: 19px !important;
  }
  .card p {
    font-weight: 300;
    margin: 0.5em 0 2em 0;
    font-size: 15px !important;
  }
  .icons {
    width: 50%;
    min-width: 180px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  .card a {
    text-decoration: none;
    color: inherit;
    font-size: 1.4em;
  }

  .card:hover{
    transform: scale(1.05);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }


.custom-buttons {
    display: inline-block;
    line-height: 2;
    background-color: #900404 !important;
    /* border-radius: 18px 18px 18px 18px; */
    border: 2px solid #900404 !important;
    padding: 12px 24px;
    font-family: DINPro !important;
    color: #fff;
    fill: #fff;
    text-align: center;
    width: 44%;
    transition: all .3s;
}

.custom-buttons .custom-align-icon-left {
    margin-right: 5px;
    order: 5;
}
.btn-primary{
    background-color:#900404  !important; 
    color: white !important;
    border-color: #900404 !important;
}
.btn-primary:hover{
    color: #f6c93f !important;
}
.fa {
    font-family: DINPro;
    font-weight: 400;
}

.fa-edit:before {
    content: "\f044";
}

/* .custom-buttons:hover {
    background-color: #900404 !important;
    color: #ffffff !important;
    fill: #900404 !important;
} */

.custom-buttons-text {
    font-family: DINPro;
    font-weight: 400;
    line-height: 1.5;
    font-size: 14px;
}

@media (max-width: 768px) {
    .mobile-grid {
        display: block !important;
    }

    .col {
        margin-bottom: 0px;
    }
}

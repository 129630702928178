.no-bottom {
  padding-bottom: 0;
}

.gap {
  padding: 30px 0;
  position: relative;
}

.green-overlay {
  z-index: 0;
  background-color: #111d5e;
}

.green-overlay:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.parallax {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  top: 0;
  left: 0;
}

.footer-one ul.quick-links {
  margin-top: 35px;
}

.footer-one ul.quick-links li {
  position: relative;
  margin-bottom: 12px;
}

.footer-one ul.quick-links li::before {
  width: 12px;
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}

.footer-one ul.quick-links li a {
  font-size: 15px;
  color: #ffffff;
}

.footer-one h3 {
  font-size: 23px;
}

.quick-links {
  list-style-type: none;
  padding: 0;
}

.quick-links li {
  display: flex;
  margin-right: 20px;
}

.quick-links li a {
  text-decoration: none;
  color: black;
}

.quick-links li i {
  margin-right: 5px;
}

.quick-links li a {
  color: #000;
  transition: color 0.3s ease;
}

.quick-links li a:hover {
  color: #f6c93f !important;
}

.copydue a:hover {
  color: #f6c93f !important;
}

.magazine {
  color: white;
  margin-top: 18px;
  font-size: 19px;
}

.magazine-border {
  /* border-bottom: 4px solid #f6c93f; */
  width: 26%;
  border-radius: 5px;
}

@media (max-width: 500px) {
  .bottom-border {
    /* border-bottom: 4px solid #f6c93f; */
    width: 26%;
    border-radius: 5px;
    margin-bottom: 15px !important;
  }
}

.newsletter-signup {
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  margin: 0 auto;
}

#email-data {
  width: 67%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.btn-submit {
  width: 30%;
  padding: 8px;
  border: none;
  background-color: #f6c93f;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 7px;
}